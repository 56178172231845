<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" scrollable max-width="370px">
                <v-card>
                    <v-card-title>
                        <span class="subtitle-1"><v-icon left>mdi-format-list-checkbox</v-icon> Izberite polja za prikaz</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 350px;">
                        <v-checkbox 
                        v-for="(item, index) in this.columns" 
                        :key="index"
                        v-model="selectedItems" 
                        :label="item.text" 
                        :value="item.value">
                        </v-checkbox>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                    <v-btn color="success" text @click="save()">Shrani</v-btn>
                    <v-btn color="error" text @click="cancel()">Prekliči</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'

export default {
    name: 'data-table-toggle-columns',
    props: ['dataTableId', 'columns', 'dataTableColumnsMenuId'],
    data: () => ({
        dialog: false,
        selectedItems: [],
        timeout1: null,
    }),

    methods: {
        save() {
            window.console.log(this.dataTableId)
            window.console.log(this.selectedItems)
            EventBus.$emit(this.dataTableId, {
                dataTableHeaders: this.selectedItems
            })

            this.saveToStore()

            this.dialog = false
        },

        cancel() {
            this.dialog  = false

            var vm = this
            if(vm.columns != null && vm.columns.length > 0) {
                    this.columns.forEach(column => {
                    if(column.visibility) {
                        vm.selectedItems.push(column.value)
                    }
                })
            }
        },

        saveToStore() {
            window.console.log("#### DataTableToggleColumns@saveToStore ###")
            let settingValue = null;

            if(this.dataTableId === 'dtEvents') {
                settingValue = "dt_events"
            }

            if(this.dataTableId == 'dtUsers') {
                settingValue = 'dt_users'
            }

            if(this.dataTableId == 'dtExports') {
                settingValue = 'dt_exports'
            }

            if(this.dataTableId == 'dtParkingBarriersArchive') {
                settingValue = 'dt_parking_barriers_archive';
            }

            if(settingValue !== null) {
                    this.$store.commit("APP_SETTING", {
                    setting: settingValue,
                    key: "columns",
                    value: this.selectedItems
                })
            } else {
                window.console.log("settingValue null or not set.")

            }
        },

        readFromStore() {
            let appSettings = this.$store.getters.appSettings

            if(this.dataTableId === "dtEvents") {
                this.selectedItems = appSettings.dt_events.columns
            }

            if(this.dataTableId === "dtUsers") {
                this.selectedItems = appSettings.dt_users.columns
            }

            if(this.dataTableId === "dtExports") {
                this.selectedItems = appSettings.dt_exports.columns
            }

            if(this.dataTableId === "dtParkingBarriersArchive") {
                this.selectedItems = appSettings.dt_parking_barriers_archive.columns
            }


            this.timeout1 = setTimeout(() => {
                EventBus.$emit(this.dataTableId, {
                    dataTableHeaders: this.selectedItems
                })
            }, 400)

            //window.console.log(appSettings)
        }
    },

    created() {
        var vm = this
        //window.console.log(this.dataTableId)
        EventBus.$on(this.dataTableColumnsMenuId, (data) => {
            if(data.toggleColumnsMenu) {
                setTimeout(() => {
                    vm.dialog = true
                }, 10)
            }
        })

        if(vm.columns != null && vm.columns.length > 0) {
                this.columns.forEach(column => {
                if(column.visibility) {
                    vm.selectedItems.push(column.value)
                }
            })
        }
    },

    mounted() {
        // preberi shranjene nastavitve iz local storage za prikaz
        this.readFromStore()
    },

    beforeDestroy() {
        clearTimeout(this.timeout1)
    },

    destroyed() {

    }
}

</script>

<style scoped>

</style>